import React, { useEffect, useState } from 'react'

import '../assets/css/personal_profile.css'

import loginIcon from '../assets/images/login/login.svg'
import logoIcon from '../assets/images/login/logo.svg'
import fbIcon from '../assets/images/login/fb.svg'
import googleIcon from '../assets/images/login/google.svg'
import linkedinIcon from '../assets/images/login/linkedin.svg'
import indiaIcon from '../assets/images/login/india.png'

import PhoneInput from "react-phone-number-input"
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import "react-phone-number-input/style.css"


import {
    Button,
    TextField,
    MenuItem
} from "@material-ui/core"




function Login() {

    const [value, setValue] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [gender, setGender] = useState('');
    const [city, setcity] = useState('');


    return (
        <div className="row m-0">
            <div className="col-md-6 position-relative" style={{ backgroundColor: '#1e69b5', height: '100vh' }}>
                <img className="edit-personal-profile-img" src={loginIcon} />
            </div>
            <div className="col-md-6 position-relative">
                <div className="edit-personal-profile-right text-center" >
                    <img className="edit-personal-profile-logo" src={logoIcon} />
                    <p style={{ color: "#1e69b5", margin: "20px" }}>Personal Profile</p>
                    <div className="edit-personal-profile-underline"></div>
                </div>

                <div className="edit-personal-profile-edit mx-5 mt-5" >
                    <spna className="fw-bold">Edit profile</spna>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="edit-profile-avatar">
                            </div>
                        </div>

                        <div className="col-md-9">
                            <TextField id="standard-basic" label="Name" variant="standard" className="w-100" />

                            <TextField id="standard-basic" label="Email" variant="standard" className="w-100 mt-3" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {/* <TextField id="standard-basic" label="Phone" variant="standard" className="w-100 mt-3" /> */}

                            <PhoneInput
                                // countryCallingCodeEditable={false}
                                international
                                className="w-100 mt-4"
                                countryCallingCodeEditable={false}
                                defaultCountry="FR"
                                value={mobileNumber}
                                placeholder="Contact Number"
                                onChange={e => {
                                    setMobileNumber(e.target.value)
                                }}
                            >
                            </PhoneInput>{''}

                        </div>
                        <div className="col">
                            {/* <TextField id="standard-basic" label="dob" variant="standard" className="w-100 mt-3" /> */}

                            <TextField
                                className="w-100"
                                margin="dense"
                                id="date-of-birth"
                                label="Date of Birth"
                                type="date"
                                // defaultValue={"2017-05-24"}
                                placeholder=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={dateOfBirth}
                                onChange={e => {
                                    setDateOfBirth(e.target.value)
                                }}

                            />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <TextField
                                className="w-100"
                                margin="dense"
                                id="marks-value"
                                label="Marks"
                                type="text"
                                value={value}
                                onChange={e => {
                                    setValue(e.target.value)
                                }}

                            />

                        </div>
                        <div className="col">
                            <TextField
                                className="w-100"
                                select
                                margin="dense"
                                id="gender"
                                label="Gender"
                                type="text"
                                value={gender}
                                onChange={e => {
                                    setGender(e.target.value)
                                }}

                            >
                                <MenuItem value={"male"} key={"male"}>
                                    Male
                                </MenuItem>
                                <MenuItem value={"female"} key={"female"}>
                                    Female
                                </MenuItem>
                            </TextField>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {/* <TextField id="standard-basic" label="Phone" variant="standard" className="w-100 mt-3" /> */}
                            <TextField
                                className="w-100"
                                select
                                margin="dense"
                                id="city"
                                label="Current City"
                                type="text"
                                value={city}
                                onChange={e => {
                                    setcity(e.target.value)
                                }}

                            >
                                <MenuItem value={"male"} key={"male"}>
                                    india
                                </MenuItem>
                                <MenuItem value={"female"} key={"female"}>
                                    usa
                                </MenuItem>
                            </TextField>

                        </div>
                        <div className="col">
                            <TextField
                                className="w-100"
                                select
                                margin="dense"
                                id="state"
                                label="State"
                                type="text"
                                value={city}
                                onChange={e => {
                                    setcity(e.target.value)
                                }}

                            >
                                <MenuItem value={"male"} key={"male"}>
                                    india
                                </MenuItem>
                                <MenuItem value={"female"} key={"female"}>
                                    usa
                                </MenuItem>
                            </TextField>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <TextField
                                className="w-100"
                                select
                                margin="dense"
                                id="country"
                                label="Country"
                                type="text"
                                value={city}
                                onChange={e => {
                                    setcity(e.target.value)
                                }}
                            >
                                <MenuItem value={"male"} key={"male"}>
                                    india
                                </MenuItem>
                                <MenuItem value={"female"} key={"female"}>
                                    usa
                                </MenuItem>
                            </TextField>

                        </div>
                        <div className="col">
                            {/* <TextField id="standard-basic" label="Phone" variant="standard" className="w-100 mt-3" /> */}

                        </div>
                    </div>



                    <div className="row w-100">
                        <div className="col mt-3">
                            <div className="cta-secondary">
                                <Button className="cancel-btn" color="primary" style={{color:'#3377B6'}}>
                                    Cancel
                                </Button>
                            </div>
                            <div className="cta-primary">
                                <Button
                                
                                    className="save-btn"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>



                </div>
            </div>









        </div>)
}

export default Login
